let maskPlaceholder = '+7 (999) 999-99-99'

$('.js-mask').each((index, element) => {
  let maskPlaceholder = '+7 (999) 999-99-99'

	if($(element).data('mask')){
		maskPlaceholder = $(element).data('mask')
	}

	$(element).inputmask({
    mask: maskPlaceholder,
    showMaskOnHover: false,
  })
})

$(document).on('keydown', '.js-mask', (event) => {
	if(event.which === 32) return false
})


// fix mac phone focus input
$(document).one('focus', '.js-mask', (e) => {
	setTimeout(() => {
		$(e.currentTarget)[0].setSelectionRange(4, 4)
	}, 50)
})

$('.js-mask').on('blur change', (event) => {
	var _this = $(event.currentTarget);

	setTimeout(() => {if(_this.val()!==''){_this.valid()}}, 100);
});
