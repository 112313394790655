$(document).on('change input', '.form-input', (event) => {
	inputValueChecking($(event.currentTarget));
});

$(document).on('click focus', 'input.form-input.js-datepickerFrom', (event) => {
  if (!$(event.currentTarget).closest('.form-field').find('.datepicker-inline .datepicker').hasClass('active')){
    $(event.currentTarget).closest('.form-field').find('.datepicker-inline').addClass('active')
    $(event.currentTarget).closest('.form-field').find('.datepicker-inline .datepicker').addClass('active')
  }
});

if ($('.js-datepickerFrom').length) {
  $(document).on('click','.simplebar-content', function(event) {
    const e=$(event.target).closest('.simplebar-content').find('.js-datepickerFrom').closest('.form-field');
    console.log('e = ',e.length);
    const _this = event.target;
    // if (!e.contains(event.target)) {
    // if (e.length && !$.contains($(e)[0],$(_this)[0])) {
    if (!$.contains($(e)[0],$(_this)[0])) {
      $(e).find('.datepicker-inline').removeClass('active');
      $(e).find('.datepicker-inline .datepicker').removeClass('active');
    };
  });
}

$(document).on('focus', '.form-input', (event) => {
    const input = $(event.currentTarget);
    const fieldsetLine = input.parents('.fieldset-line');

	input.parent().addClass('is-focus');
    fieldsetLine.addClass('is-focus-line');
});

$(document).on('blur', '.form-input', (event) => {
    const input = $(event.currentTarget);
    const fieldsetLine = input.parents('.fieldset-line');
    fieldsetLine.removeClass('is-focus-line');
	// inputValueChecking($(event.currentTarget));
	setTimeout(() => {
		inputValueChecking($(event.currentTarget));
	}, 100);
});

formInputCheck();

$(document).ready(function(){
  console.log('document ready');
	formInputCheck();
});

$(window).on('load', () => {
  console.log('window onLoad');
	formInputCheck();
});

function formInputCheck(){
  console.log('formInputCheck')
	$('.form-input').each((index, element) => {
		inputValueChecking($(element));
	});
  console.log('=======formInputCheck==END========');
}

function inputValueChecking(input){
  console.log('inputValueChecking item =', input);
  console.log('inputValueChecking input.val =', input.val());
	// (input.val() != '' &&  input.val() != null) ? input.parent().addClass('is-focus') : input.parent().removeClass('is-focus');
	if ((input.val() != '' && input.val() != null) || input.is( ":focus" )) {
		input.parent().addClass('is-focus');
    console.log('if 1 =');
	} else {
		input.parent().removeClass('is-focus');
    console.log('if 2 =');
	};

}
