import svg4everybody from 'svg4everybody'
import $ from 'jquery'
import 'air-datepicker'
import './vendor/jquery-formstyler'
import './vendor/jquery-validation'
import './vendor/jquery.inputmask.bundle.min'

svg4everybody()

window.$ = $
window.jQuery = $
