$(document).on('change', 'select.js-select', (event) => {
	const _this = $(event.currentTarget);
	const selectFieldBlock = _this.parents('.form-field');

	if(_this.val() != ''){
		selectFieldBlock.addClass('is-focus');
	}
	else {
		selectFieldBlock.removeClass('is-focus');
		selectFieldBlock.find('.jq-selectbox__select-text').text('');
	}

	if (_this.hasClass('js-selectOrganization')) {
		if (_this.val() == _this.data('not-required')) {
			$('.js-kpp').removeAttr('required');
		} else {
			$('.js-kpp').attr('required', 'true');
		}
	}

	if (_this.hasClass('js-hasOtherInput')) {
		if (_this.val() == _this.data('other-input')) {
			_this.parents('.form-col').find('.js-otherInput').show();
		} else {
			_this.parents('.form-col').find('.js-otherInput').hide()
		}
	}
});

$(window).on('load', function() {
	$('.js-hasOtherInput').each(function() {
		let _this = $(this);

		if (_this.val() == _this.data('other-input')) {
			_this.parents('.form-col').find('.js-otherInput').show();
		}
	})
})

$(document).on('selectInitTrigger', () => {
	$('select.js-select').each((index, element) => {
		if($(element).val() != ''){
			$(element).parents('.form-field').addClass('is-focus');
		}
	});

	$('.js-select').styler({
		singleSelectzIndex: 2,
		selectSmartPositioning: false,
		onSelectClosed: function(){
			$(this).find('select').valid();
		}
	});
});

$(document).trigger('selectInitTrigger');
