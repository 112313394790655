import './vendor'

import './components/mask'
import './components/validate'
import './components/form'
import './components/datepicker'
import './components/select'
import './components/simplebar'

import Sidebar from './components/sidebar'

new Sidebar()
