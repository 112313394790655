export function stringToDate(dateString) {
  const parts = dateString.split('.')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1] - 1, 10)
  const year = parseInt(parts[2], 10)

  return new Date(year, month, day)
}

export function dateToString(date) {
  const day = addZero(date.getDate())
  const month = addZero(date.getMonth() + 1)
  const year = date.getFullYear()
  const formatDate = day + '.' + month + '.' + year

  return formatDate
}

export function addZero(n) {
  return n > 9 ? n : `0${n}`
}
