import {
  stringToDate,
  dateToString,
  addZero
} from './helpers'

const datepickerInputs = $('.js-datepickerFrom')

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}

datepickerInputs.each((index, el) => {
  const currentDate = new Date()
  const datepickerFromInput = $(el)
  const datepickerFromValue = datepickerFromInput.val()
  const notworkingweekdays = datepickerFromInput.data('notworkingweekdays')
  const exceptions = datepickerFromInput.data('exceptions')
  const disabledDays = notworkingweekdays ? notworkingweekdays.split(',').map(i => Number(i)) : []
  const disabledExceptionsDayes = exceptions ? exceptions.split(',') : []
  let initPicker = false

  const datepickerFrom = datepickerFromInput.datepicker({
    autoClose: true,
    minDate: new Date(),
    position: 'bottom right',
    prevHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path data-name="Контур 57" d="M16 19l-7-7 7-7" fill="none" stroke="#000" stroke-miterlimit="10"/></svg>',
    nextHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path data-name="Контур 57" d="M9 5l7 7-7 7" fill="none" stroke="#000" stroke-miterlimit="10"/></svg>',
    inline: true,
    onSelect: function(formattedDate, date, inst){
      $(inst.$el).trigger('change')
      if (initPicker) {
        getTimeslot({
          date: dateToString(date),
          el: $(inst.$el),
        })
      }
    },
    onShow: function(inst, animationCompleted){
      $(inst.$el).addClass('is-open')
    },
    onHide: function(inst, animationCompleted){
      $(inst.$el).removeClass('is-open')
      $('.date-error').removeClass('is-success')
      $('.date-error').addClass('is-error')
      if ($(inst.$el).closest('.form-field').find('.datepicker-inline').hasClass('active')) {
        $(inst.$el).closest('.form-field').find('.datepicker-inline').removeClass('active')
      }
      if ($(inst.$el).closest('.form-field').find('.datepicker').hasClass('active')) {
        $(inst.$el).closest('.form-field').find('.datepicker').removeClass('active')
      }
    },
    onChangeMonth: function (month, year) {
      getBusydays({
        month: month + 1,
        year: year,
        el: $(el),
        disabledDays,
        datepickerFrom,
        disabledExceptionsDayes,
      })
    },
    onRenderCell: function (date, cellType) {
      if (cellType == 'day') {
        const day = date.getDay()
        const isDisabled = disabledDays.indexOf(day) != -1 ||
          disabledExceptionsDayes.includes(dateToString(date))

        return {
          disabled: isDisabled
        }
      }
    }
  }).data('datepicker')

  if(datepickerFromValue) datepickerFrom.selectDate(stringToDate(datepickerFromValue))

  datepickerFromInput.on('change', (event) => {
    const _this = $(event.currentTarget)
    if(_this.val() == '') datepickerFrom.clear()
  })

  initPicker = true

  getBusydays({
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
    el: datepickerFromInput,
    disabledDays,
    datepickerFrom,
    disabledExceptionsDayes,
  })
})

function getTimeslot({
  el,
  date,
}) {
  const url = '/ajax/?action=GETTIMESLOT'
  const $form = el.parents('form')
  const $selectTime = $form.find('[name="TIME"]')
  const formType = $form.find('[name="form"]').val()

  $.ajax({
    url: `${url}&day=${date}&form=${formType}`,
    success: function(response){
      const data = JSON.parse(response)

      if (data.success === true) {
        const options = data.data

        $selectTime.html('')

        for (var option in options) {
          $selectTime.append(`
            <option value="${option}">${options[option]}</option>
          `)
        }

        $selectTime.trigger('refresh')
        $(document).trigger('selectInitTrigger')
      }
      else {
        alert(data.error)
        $selectTime.html('')
        $selectTime.append(`
          <option selected disabled value=''>Нет доступного времени</option>
        `)
        $selectTime.trigger('refresh')
        $(document).trigger('selectInitTrigger')
      }
    },
  });
}

function getBusydays({
  el,
  month,
  year,
  disabledDays,
  datepickerFrom,
  disabledExceptionsDayes,
}) {
  const url = '/ajax/?action=GETBUSYDAYS'
  const $form = el.parents('form')
  const formType = $form.find('[name="form"]').val()

  $.ajax({
    url: `${url}&month=${month}&year=${year}&form=${formType}`,
    success: function(response){
      const data = JSON.parse(response)

      if (data.success === true) {
        datepickerFrom.update('onRenderCell', function (date, cellType) {
          if (cellType == 'day') {
            const day = date.getDay()
            const isDisabled = disabledDays.indexOf(day) != -1 ||
              [...disabledExceptionsDayes, ...data.data].includes(dateToString(date))

            return {
              disabled: isDisabled
            }
          }
        })
      }
    },
  });
}
